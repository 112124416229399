<template>
    <v-layout v-if="ready" justify-center>
        <v-responsive max-width="800" width="100%" height="100%" class="px-3">
            <div class="headline text-start mt-8">폼메일 상세</div>

            <v-card class="mt-6">
                <v-card-title class="subtitle-1">기본정보</v-card-title>
                <v-divider />
                <v-card-text>
                    <v-row v-if="$route.params._form">
                        <v-col cols="6" lg="3">
                            <v-text-field :value="form.name" label="작성자" readonly persistent-placeholder dense hide-details />
                        </v-col>
                        <v-col cols="6" lg="3">
                            <v-text-field v-model="form.phone" label="연락처" persistent-placeholder dense hide-details />
                        </v-col>
                        <v-col cols="6" lg="3">
                            <v-text-field :value="form.createdAt ? form.createdAt.toDateTime() : undefined" label="작성일" persistent-placeholder dense hide-details readonly disabled />
                        </v-col>
                        <!-- <v-col cols="6" lg="3">
                            <v-text-field v-model="form.email" label="이메일" persistent-placeholder dense hide-details />
                        </v-col> -->
                    </v-row>
                </v-card-text>
            </v-card>
            
            <!-- <v-card class="mt-6">
                <v-card-title class="subtitle-1">폼메일 파일</v-card-title>
                <v-card-text>
                    <v-col>
                        <v-btn :href="form.file" download color="primary">폼메일 파일 다운로드</v-btn>
                    </v-col>
                </v-card-text>
            </v-card> -->

            <v-card class="mt-6">
                <v-card-title class="subtitle-1">내용</v-card-title>
                <v-divider />
                <v-card-text>
                    <v-textarea v-model="form.content" readonly hide-details dense auto-grow />
                </v-card-text>
            </v-card>

            <v-row justify="center">
                <v-col md="auto" sm="auto">
                    <v-btn color="primary" @click="save">확인</v-btn>
                </v-col>
            </v-row>
        </v-responsive>
    </v-layout>
</template>

<script>
import ImagePopup from "@/components/console/dumb/image-popup.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";

import api from "@/api";
import { mdiTagPlus } from "@mdi/js";

export default {
    components: {
        ImagePopup,
        NaverSmarteditor,
    },
    data() {
        return {
            mdiTagPlus,
            ready: false,

            ///////////////////////////////////////////////
            // 게시글 정보
            ///////////////////////////////////////////////
            form: {
                code: this.$route.query.code,
                category: undefined,

                subject: undefined,
                content: undefined,
                summary: undefined,
                period: undefined,
                createdAt: undefined,

                sequence: 1,

                storeName: undefined,
                storePhone: undefined,
                storeEmail: undefined,

                sido: undefined,
                gugun: undefined,
                area: undefined,
                postcode: undefined,
                address1: undefined,
                address2: undefined,
                lat: undefined,
                lng: undefined,

                writer: {
                    name: undefined,
                    phone: undefined,
                    email: undefined,
                },

                collections: [],
                relativeProducts: [],
                portfolios: [],

                thumb: undefined,
            },
        };
    },
    computed: {
        headline() {
            if (this.form.code == "consignment-sale") return "위탁신청 상세";
            return "폼메일 상세";
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                if (this.$route.params._form) {
                    var { form } = await api.console.forms.get({ _id: this.$route.params._form });
                    this.form = form;

                    if (form.thumb) this.form.thumb = await api.getResource(form.thumb);
                }

                this.ready = true;
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },
        async save() {
            try {
                ////////////////////////////////////////////////////////////////
                // 1. 게시글 저장
                ////////////////////////////////////////////////////////////////
                var { form } = this.form._id ? await api.console.forms.put(this.form) : await api.console.forms.post(this.form);

                ////////////////////////////////////////////////////////////////
                // 2. 리소스 저장
                ////////////////////////////////////////////////////////////////
                if (this.form.thumb) await api.console.forms.postThumb(form, this.form.thumb);

                alert(this.form._id ? "수정되었습니다" : "저장되었습니다");

                this.$router.go(-1);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        createObjectURL(file) {
            try {
                return URL.createObjectURL(file);
            } catch (error) {
                return;
            }
        },

        isCode(codes = []) {
            return codes.includes(this.form.code);
        },
    },
};
</script>
