<template>
  <v-data-table v-if="ready" :headers="headers" :items="items"  disable-pagination hide-default-footer class="elevation-1 mt-4">
    <template #[`item.no`]="{ item }">
        {{ (+items.indexOf(item)+1) }}
    </template>
    <template #[`item.rank`]="{ item }">
        {{ item.rank }}
    </template>
    <template #[`item.thumbnail`]="{ item }">
        <v-row>
            <v-img v-if="item?.thumbnail" :src="item?.thumbnail" width="100" height="100" contain></v-img>
        </v-row>
    </template>
    <template #[`item.videoId`]="{ item }">
        {{ item.videoId }}
    </template>
    <template #[`item.actions`]="{ item }">
        <v-icon small @click="edit(item)" title="수정">mdi-pencil</v-icon>
        <v-icon small @click="remove(item)" title="삭제" class="ml-2">mdi-delete</v-icon>
    </template>
    <template #footer>
        <v-divider />
        <v-row class="mx-0">
            <v-spacer />
            <v-dialog v-model="dialog" max-width="720px">
                <template v-slot:activator="{ on }">
                    <v-btn fab text small color="primary" v-on="on" @click="clean()" v-show="!isFull">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </template>
                <v-card>
                    <v-card-title>
                        <span class="subtitle-1">미디어 등록</span>
                    </v-card-title>
                    <v-divider/>

                    <v-card-text>
                        <v-text-field v-model="editItem.rank" label="우선 순위" hide-details class="mt-3"></v-text-field>
                        <v-row align="center" class="pt-2">
                            <v-col cols="auto" class="py-0">
                                <v-img v-if="editItem.thumbnail" max-width="96" max-height="96" :src="createObjectURL(editItem.thumbnail)" contain></v-img>
                                <v-responsive v-else width="96" height="96" class="grey lighten-2">
                                    <v-row align="center" class="fill-height" justify="center"><v-icon color="black">mdi-pencil</v-icon></v-row>
                                </v-responsive>
                            </v-col>
                            <v-col class="py-0">
                                <v-file-input v-model="editItem.thumbnail" label="비디오 썸네일" persistent-placeholder class="mt-2 mx-3" show-size accept="image/*"></v-file-input>
                            </v-col>
                        </v-row>
                        <v-text-field v-model="editItem.videoId" label="비디오 아이디" hide-details class="mt-3"></v-text-field>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="close">취소</v-btn>
                        <v-btn color="primary" text @click="save">저장</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </template>

    <v-dialog v-model="dialog" max-width="720px">
        <v-card>
        <v-card-title>
            <span class="subtitle-1">상품문의</span>
        </v-card-title>
        </v-card>
    </v-dialog>
  </v-data-table>
</template>

<script>
import api from "@/api";
import { attrs_input } from "@/assets/variables";

const headers = [
    { text: "no", align: "center", width: 100, value: "no", sortable: true },
    { text: "우선순위", align: "center", value: "rank", width: 100, sortable: true},
    { text: "썸네일", align: "center", value: "thumbnail", sortable: false},
    { text: "비디오 아이디", align: "center", value: "videoId", width: 200, sortable: false},
    { text: "Actions", width: 40, align: "center", value: "actions", sortable: false},
];

export default {
    data() {
        return{
            items: [],
            editItem: {},
    
            headers,
            attrs_input,
            dialog: false,
            ready: true
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            this.ready = false
            await this.search();
            this.ready = true
            this.editItem = {};
        },
        async search(){
            let { summary, medias } = await api.console.media.gets({});
            this.items = medias;
        },
        async save(){
            try{
                if(!this.validation()) return;
                let { thumbnail, ...editItem } = this.editItem;
                let { put, post } = api.console.media;
                const { media } = editItem._id? await put({...editItem, rank: +editItem.rank}): await post({...editItem, rank: +editItem.rank});

                if(thumbnail) await api.console.media.postThumb({...media}, thumbnail);

                alert("저장되었습니다");

                this.dialog = false;
                this.init();
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },
        validation(){
            if(!this.editItem.rank) throw new Error("우선순위를 입력해주세요");
            if(/[^\d]/.test(this.editItem.rank)) throw new Error("우선순위는 숫자만 입력해주세요.");
            if(!this.editItem.thumbnail) throw new Error("썸네일을 첨부해주세요");
            if(!this.editItem.videoId) throw new Error("비디오 아이디를 입력해주세요");

            return true;
        },
        async edit(item){
            try{
                this.editItem = { ...item };
                if(item.thumbnail) this.editItem.thumbnail = await api.getResource(item.thumbnail, true);
                this.dialog = true;

            } catch(e){
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },
        async remove(item){
            if(confirm("미디어를 삭제하시겠습니까?")){
                try{
                    await api.console.media.delete(item);
                    alert("삭제되었습니다.");
                    this.init();
                } catch(e){
                    console.error(error);
                    if(error.response) alert(error.response.data.message);
                    else alert(error.message);
                }
            } else return ;
        },
        close(){
            this.dialog = false;
        },
        createObjectURL(file) {
            try {
                return URL.createObjectURL(file);
            } catch (error) {
                return;
            }
        },
        clean(){
            this.editItem = {};
        }
    },
    computed:{
        isFull(){
            return this.items.length >= 8;
        }
    }
}
</script>

<style>

</style>