<template>
    <v-layout justify-center>
        <v-responsive max-width="1280px" width="100%">
            <v-row class="mt-8" justify="center" align="center">
                <v-col cols="auto" class="pt-0 pb-0 headline text-start">게시글 목록</v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto" class="pt-0 pb-0">
                    <v-btn class="ma-2" color="secondary" rounded @click="showSearch = !showSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                </v-col>
            </v-row>

            <v-expand-transition>
                <v-card v-show="showSearch" class="elevation-1 mx-auto">
                    <v-card-title class="subtitle-2 font-weight-bold">검색필터</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="6" class="py-0">
                                <v-date-field v-model="filter.createdAt[0]" label="작성일자" persistent-placeholder hide-details></v-date-field>
                            </v-col>
                            <v-col cols="12" sm="6" class="py-0">
                                <v-date-field v-model="filter.createdAt[1]" label="작성일자" persistent-placeholder hide-details></v-date-field>
                            </v-col>
                        </v-row>
                        <v-row class="mt-3">
                            <v-col cols="6" md="3" class="py-0">
                                <v-select v-model="filter.searchKey" :items="searchKeys" label="검색조건" hide-details persistent-placeholder  @change="()=>filter.searchValue=null"></v-select>
                            </v-col>
                            <v-col cols="6" md="3" class="py-0" v-if="[null, 'name', 'job', 'rank'].includes(filter.searchKey)">
                                <v-text-field v-model="filter.searchValue" label="검색어" persistent-placeholder hide-details :disabled="!filter.searchKey" @keydown.enter="(page = 1) && search()"></v-text-field>
                            </v-col>
                            <v-col cols="6" md="3" class="py-0" v-if="['type'].includes(filter.searchKey)">
                                <v-select v-model="filter.searchValue" :items="lawyerItems" label="검색어" persistent-placeholder hide-details></v-select>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" rounded text @click="(page = 1) && search()"><v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색</v-btn>
                    </v-card-actions>
                </v-card>
            </v-expand-transition>

            <v-data-table :items="lawyers" :headers="lawyerHeaders.filter((header) => (header.test ? header.test($route.query.code) : true))" disable-sort disable-pagination hide-default-footer class="elevation-1 mt-4">
                <template #[`item.no`]="{ item }">
                    {{ (page - 1) * limit + (+lawyers.indexOf(item) + 1) }}
                </template>
                <template #[`item.isRepresentative`]="{ item }">
                    {{ item.isRepresentative ? "대표" : "사원" }}
                </template>
                <template #[`item.type`]="{ item }">
                    {{ item.type === MEMBER_TYPES.LAWYER.value ? "변호사" : "자문단" }}
                </template>
                <!-- <template #[`item.career`]="{ item }">
                    <span v-for="career, index in item.career" :key="index">
                        / {{ career.txt }}
                    </span>
                </template> -->
                <template #[`item.listImage`]="{ item }">
                    <v-row>
                        <v-img v-if="item?.listImage" :src="item?.listImage" width="100" height="100" contain></v-img>
                    </v-row>
                </template>
                <template #[`item.viewImage`]="{ item }">
                    <v-row>
                        <v-img v-if="item?.viewImage" :src="item?.viewImage" width="100" height="100" contain></v-img>
                    </v-row>
                </template>
                <template #[`item.mainImage`]="{ item }">
                    <v-row>
                        <v-img v-if="item?.mainImage" :src="item?.mainImage" width="100" height="100" contain></v-img>
                    </v-row>
                </template>
                <template #[`item.meta.href`]="{ value }">
                    <v-btn v-if="!!value" icon text :href="value" target="_blank">
                        <v-icon>mdi-link</v-icon>
                    </v-btn>
                    <template v-else>-</template>
                </template>
                <template #[`item.createdAt`]="{ item }">
                    {{ item.createdAt.toDateTime() }}
                </template>
                <template #[`item.actions`]="{item}">
                    <v-icon small @click="edit(item)" title="수정">mdi-pencil</v-icon>
                    <v-icon v-if="false" small @click="copy(item)" title="복사" class="ml-2">mdi-content-copy</v-icon>
                    <v-icon small @click="remove(item)" title="삭제" class="ml-2">mdi-delete</v-icon>
                </template>
            </v-data-table>
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12" @input="search()"></v-pagination>

            <v-btn bottom color="accent" fab fixed right @click="create()">
                <v-icon>mdi-pencil</v-icon>
            </v-btn>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { MEMBER_TYPES } from "@/assets/variables";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";

const lawyerHeaders = [
    { text: "no", align: "center", width: 20, value: "no" },
    { text: "직급", align: "center", width: 40, value: "isRepresentative"},
    { text: "카테고리", align: "center", width: 40, value: "type"},
    { text: "이름", align: "center", width: 40, value: "name" },
    { text: "직업", align: "center", width: 60, value: "job" },
    // { text: "경력 및 학력", align: "center", width: 200, value: "career" },
    { text: "프로필 이미지", align: "center", width: 70, value: "viewImage" },
    { text: "리스트 이미지", align: "center", width: 70, value: "listImage" },
    { text: "메인 이미지", align: "center", width: 70, value: "mainImage" },
    { text: "우선순위", align: "center", width: 50, value: "rank" },
    { text: "작성일", align: "center", width: 70, value: "createdAt" },
    { text: "Actions", width: 40, align: "center", value: "actions" },
];

export default {
    components: {
        VDateField
    },
    data() {
        return {
            ///////////////////////////////////////////////
            // 검색필터
            ///////////////////////////////////////////////
            showSearch: true,

            category: null,
            filter: {
                code: this.$route.query.code,
                searchKey: this.$route.query.searchKey,
                searchValue: this.$route.query.searchValue,
                createdAt: [(this.$route.query.createdAt || [])[0], (this.$route.query.createdAt || [])[1]],
            },

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 10,

            lawyers: [],
            lawyerHeaders,

            searchKeys: [
                { text: "전체", value: null },
                { text: "이름", value: "name" },
                { text: "직업", value: "job" },
                { text: "우선순위", value: "rank" },
                { text: "카테고리", value: "type" },
            ],
            searchKey: null,
            searchValue: null,
            MEMBER_TYPES,
            lawyerItems:[
                { text: MEMBER_TYPES.LAWYER.text, value: MEMBER_TYPES.LAWYER.value },
                { text: MEMBER_TYPES.ADVISORY.text, value: MEMBER_TYPES.ADVISORY.value },
            ]
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.search(false);
        },
        async search(routable = true) {
            try {
                if (routable) {
                    this.$router.push({
                        query: Object.assign({}, this.filter, { page: this.page }),
                    });
                }

                var { summary, lawyers } = await api.console.lawyers.gets({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit,
                    },
                    params: this.filter,
                });

                this.pageCount = Math.ceil(summary.totalCount / this.limit);
                this.lawyers = lawyers;
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        create() {
            this.$router.push(`/console/lawyers/create`);
        },
        edit(lawyer) {
            this.$router.push(`/console/lawyers/${lawyer._id}`);
        },
        async remove(lawyer) {
            try {
                if (confirm("게시글을 삭제하시겠습니까?")) {
                    await api.console.lawyers.delete(lawyer);
                    await this.search(false);

                    alert("삭제되었습니다");
                }
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
    watch: {
        $route() {
            this.search(false);

            Object.assign(this.filter, this.$route.query);
            this.page = +this.$route.query.page || 1;
        },
    },
};
</script>
