var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "console-banners",
    attrs: {
      "fluid": "",
      "fill-height": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "overflow-visible",
    attrs: {
      "max-width": "1280px",
      "width": "100%"
    }
  }, [_c('v-row', {
    staticClass: "mt-8",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pt-0 pb-0 headline text-start",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" 메인 인사이트 관리 ")]), _c('v-spacer'), _c('v-col', {
    staticClass: "pt-0 pb-0",
    attrs: {
      "cols": "auto"
    }
  })], 1), _c('div', {
    staticClass: "caption primary--text mt-2"
  }, [_c('b', [_vm._v("* 추천 이미지 사이즈 400*200(px)")])]), _c('div', {
    staticClass: "caption mt-2"
  }, [_c('b', [_vm._v("* 순서값에 따라 오름차순으로 정렬됩니다. (큰 숫자일 수록 뒤에 보입니다.)")])]), _c('v-data-table', {
    staticClass: "elevation-1 mt-4",
    attrs: {
      "items": _vm.insights,
      "headers": _vm.insightsHeaders,
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: `item.no`,
      fn: function (_ref) {
        var item = _ref.item;
        return [!item.editable ? _c('span', [_vm._v(_vm._s(item.no))]) : _c('v-text-field', {
          attrs: {
            "outlined": "",
            "dense": "",
            "hide-details": ""
          },
          model: {
            value: item.no,
            callback: function ($$v) {
              _vm.$set(item, "no", $$v);
            },
            expression: "item.no"
          }
        })];
      }
    }, {
      key: `item.label`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [!item.editable ? _c('span', [_vm._v(_vm._s(item.label))]) : _c('v-text-field', {
          attrs: {
            "outlined": "",
            "dense": "",
            "hide-details": ""
          },
          model: {
            value: item.label,
            callback: function ($$v) {
              _vm.$set(item, "label", $$v);
            },
            expression: "item.label"
          }
        })];
      }
    }, {
      key: `item.subject`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [!item.editable ? _c('span', [_vm._v(_vm._s(item.subject))]) : _c('v-text-field', {
          attrs: {
            "outlined": "",
            "dense": "",
            "hide-details": ""
          },
          model: {
            value: item.subject,
            callback: function ($$v) {
              _vm.$set(item, "subject", $$v);
            },
            expression: "item.subject"
          }
        })];
      }
    }, {
      key: `item.image`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [item.image ? _c('v-img', {
          staticClass: "grey lighten-4",
          attrs: {
            "src": item.image.src,
            "width": "200",
            "max-width": "200",
            "min-height": "50",
            "contain": ""
          }
        }) : _vm._e(), item.editable ? _c('v-file-btn', {
          attrs: {
            "color": "blue",
            "outlined": "",
            "width": "100%",
            "accept": ".jpg,.png"
          },
          on: {
            "change": function (image) {
              return _vm.upload(item, image);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "size": "20",
            "left": ""
          }
        }, [_vm._v("mdi-cloud-upload")]), _vm._v("이미지 업로드 ")], 1) : _vm._e()];
      }
    }, {
      key: `item.url`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [!item.editable ? _c('span', [_vm._v(_vm._s(item.url))]) : _c('v-text-field', {
          attrs: {
            "outlined": "",
            "dense": "",
            "hide-details": ""
          },
          model: {
            value: item.url,
            callback: function ($$v) {
              _vm.$set(item, "url", $$v);
            },
            expression: "item.url"
          }
        })];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref6) {
        var item = _ref6.item;
        return [!item.editable ? _c('v-btn', {
          attrs: {
            "color": "grey",
            "outlined": ""
          },
          on: {
            "click": function ($event) {
              return _vm.edit(item);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "small": "",
            "left": ""
          }
        }, [_vm._v("mdi-pencil")]), _vm._v("수정")], 1) : _c('v-btn', {
          attrs: {
            "color": "blue",
            "outlined": ""
          },
          on: {
            "click": function ($event) {
              return _vm.save(item);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "small": "",
            "left": ""
          }
        }, [_vm._v("mdi-content-save")]), _vm._v("저장")], 1)];
      }
    }, {
      key: `item.actions2`,
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_c('v-icon', {
          attrs: {
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.remove(item);
            }
          }
        }, [_vm._v("mdi-delete")])];
      }
    }, {
      key: `body.append`,
      fn: function () {
        return [_c('tr', [_c('td', [_c('v-text-field', {
          attrs: {
            "outlined": "",
            "dense": "",
            "hide-details": ""
          },
          model: {
            value: _vm.editItem.no,
            callback: function ($$v) {
              _vm.$set(_vm.editItem, "no", $$v);
            },
            expression: "editItem.no"
          }
        })], 1), _c('td', [_c('v-text-field', {
          attrs: {
            "outlined": "",
            "dense": "",
            "hide-details": ""
          },
          model: {
            value: _vm.editItem.label,
            callback: function ($$v) {
              _vm.$set(_vm.editItem, "label", $$v);
            },
            expression: "editItem.label"
          }
        })], 1), _c('td', [_c('v-text-field', {
          attrs: {
            "outlined": "",
            "dense": "",
            "hide-details": ""
          },
          model: {
            value: _vm.editItem.subject,
            callback: function ($$v) {
              _vm.$set(_vm.editItem, "subject", $$v);
            },
            expression: "editItem.subject"
          }
        })], 1), _c('td', [_c('div', [_vm.editItem.image ? _c('v-img', {
          staticClass: "grey lighten-4",
          attrs: {
            "src": _vm.editItem.image.src,
            "width": "200",
            "max-width": "200",
            "min-height": "50",
            "contain": ""
          }
        }) : _vm._e(), _c('v-file-btn', {
          attrs: {
            "color": "blue",
            "outlined": "",
            "width": "100%",
            "accept": ".jpg,.png"
          },
          on: {
            "change": function (image) {
              return _vm.upload(_vm.editItem, image);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "size": "20",
            "left": ""
          }
        }, [_vm._v("mdi-cloud-upload")]), _vm._v("이미지 업로드 ")], 1)], 1)]), _c('td', [_c('v-text-field', {
          attrs: {
            "outlined": "",
            "dense": "",
            "hide-details": ""
          },
          model: {
            value: _vm.editItem.url,
            callback: function ($$v) {
              _vm.$set(_vm.editItem, "url", $$v);
            },
            expression: "editItem.url"
          }
        })], 1), _c('td', [_c('v-btn', {
          attrs: {
            "color": "blue",
            "outlined": ""
          },
          on: {
            "click": function ($event) {
              return _vm.save(_vm.editItem);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "small": "",
            "left": ""
          }
        }, [_vm._v("mdi-content-save")]), _vm._v("저징")], 1)], 1)])];
      },
      proxy: true
    }], null, true)
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }