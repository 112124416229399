var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "console-terms",
    attrs: {
      "fluid": "",
      "fill-height": ""
    }
  }, [_vm.setting ? _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "800px",
      "width": "100%"
    }
  }, [_c('v-row', {
    staticClass: "mt-8",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pt-0 pb-0 headline text-start",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("미디어 설정")])], 1), _c('div', {
    staticClass: "caption red--text mt-2"
  }, [_c('b', [_vm._v("* 비디오 아이디 추출 방법")])]), _c('div', {
    staticClass: "caption red--text mt-2"
  }, [_vm._v("유튜브 공유버튼 클릭 -> \"https://youtu.be/\"와 \"?\" 사이의 문자 복사하시면 됩니다.")]), _c('div', {
    staticClass: "caption red--text mt-2"
  }, [_vm._v("예) \"https://youtu.be/hJ431OEvf00?feature=shared\" => \"hJ431OEvf00\" ")]), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v(" 미디어 등록 ")]), _c('v-divider'), _c('media-table')], 1)], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }