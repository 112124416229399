var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.ready ? _c('v-data-table', {
    staticClass: "elevation-1 mt-4",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.items,
      "disable-pagination": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: `item.no`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(+_vm.items.indexOf(item) + 1) + " ")];
      }
    }, {
      key: `item.rank`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.rank) + " ")];
      }
    }, {
      key: `item.thumbnail`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('v-row', [item !== null && item !== void 0 && item.thumbnail ? _c('v-img', {
          attrs: {
            "src": item === null || item === void 0 ? void 0 : item.thumbnail,
            "width": "100",
            "height": "100",
            "contain": ""
          }
        }) : _vm._e()], 1)];
      }
    }, {
      key: `item.videoId`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item.videoId) + " ")];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('v-icon', {
          attrs: {
            "small": "",
            "title": "수정"
          },
          on: {
            "click": function ($event) {
              return _vm.edit(item);
            }
          }
        }, [_vm._v("mdi-pencil")]), _c('v-icon', {
          staticClass: "ml-2",
          attrs: {
            "small": "",
            "title": "삭제"
          },
          on: {
            "click": function ($event) {
              return _vm.remove(item);
            }
          }
        }, [_vm._v("mdi-delete")])];
      }
    }, {
      key: "footer",
      fn: function () {
        return [_c('v-divider'), _c('v-row', {
          staticClass: "mx-0"
        }, [_c('v-spacer'), _c('v-dialog', {
          attrs: {
            "max-width": "720px"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref6) {
              var on = _ref6.on;
              return [_c('v-btn', _vm._g({
                directives: [{
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isFull,
                  expression: "!isFull"
                }],
                attrs: {
                  "fab": "",
                  "text": "",
                  "small": "",
                  "color": "primary"
                },
                on: {
                  "click": function ($event) {
                    return _vm.clean();
                  }
                }
              }, on), [_c('v-icon', [_vm._v("mdi-plus")])], 1)];
            }
          }], null, false, 960675721),
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v;
            },
            expression: "dialog"
          }
        }, [_c('v-card', [_c('v-card-title', [_c('span', {
          staticClass: "subtitle-1"
        }, [_vm._v("미디어 등록")])]), _c('v-divider'), _c('v-card-text', [_c('v-text-field', {
          staticClass: "mt-3",
          attrs: {
            "label": "우선 순위",
            "hide-details": ""
          },
          model: {
            value: _vm.editItem.rank,
            callback: function ($$v) {
              _vm.$set(_vm.editItem, "rank", $$v);
            },
            expression: "editItem.rank"
          }
        }), _c('v-row', {
          staticClass: "pt-2",
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          staticClass: "py-0",
          attrs: {
            "cols": "auto"
          }
        }, [_vm.editItem.thumbnail ? _c('v-img', {
          attrs: {
            "max-width": "96",
            "max-height": "96",
            "src": _vm.createObjectURL(_vm.editItem.thumbnail),
            "contain": ""
          }
        }) : _c('v-responsive', {
          staticClass: "grey lighten-2",
          attrs: {
            "width": "96",
            "height": "96"
          }
        }, [_c('v-row', {
          staticClass: "fill-height",
          attrs: {
            "align": "center",
            "justify": "center"
          }
        }, [_c('v-icon', {
          attrs: {
            "color": "black"
          }
        }, [_vm._v("mdi-pencil")])], 1)], 1)], 1), _c('v-col', {
          staticClass: "py-0"
        }, [_c('v-file-input', {
          staticClass: "mt-2 mx-3",
          attrs: {
            "label": "비디오 썸네일",
            "persistent-placeholder": "",
            "show-size": "",
            "accept": "image/*"
          },
          model: {
            value: _vm.editItem.thumbnail,
            callback: function ($$v) {
              _vm.$set(_vm.editItem, "thumbnail", $$v);
            },
            expression: "editItem.thumbnail"
          }
        })], 1)], 1), _c('v-text-field', {
          staticClass: "mt-3",
          attrs: {
            "label": "비디오 아이디",
            "hide-details": ""
          },
          model: {
            value: _vm.editItem.videoId,
            callback: function ($$v) {
              _vm.$set(_vm.editItem, "videoId", $$v);
            },
            expression: "editItem.videoId"
          }
        })], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "text": ""
          },
          on: {
            "click": _vm.close
          }
        }, [_vm._v("취소")]), _c('v-btn', {
          attrs: {
            "color": "primary",
            "text": ""
          },
          on: {
            "click": _vm.save
          }
        }, [_vm._v("저장")])], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }], null, true)
  }, [_c('v-dialog', {
    attrs: {
      "max-width": "720px"
    },
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "subtitle-1"
  }, [_vm._v("상품문의")])])], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }