<template>
    <v-container fluid fill-height class="console-banners">
        <v-layout justify-center>
            <v-responsive max-width="1280px" width="100%" class="overflow-visible">
                <v-row class="mt-8" justify="center" align="center">
                    <v-col cols="auto" class="pt-0 pb-0 headline text-start">
                        메인 인사이트 관리
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="pt-0 pb-0">
                    </v-col>
                </v-row>

                <div class="caption primary--text mt-2"><b>* 추천 이미지 사이즈 400*200(px)</b></div>
                <div class="caption mt-2"><b>* 순서값에 따라 오름차순으로 정렬됩니다. (큰 숫자일 수록 뒤에 보입니다.)</b></div>

                <v-data-table :items="insights" :headers="insightsHeaders" disable-sort disable-pagination hide-default-footer class="elevation-1 mt-4">
                    <template #[`item.no`]="{item}">
                        <span v-if="!item.editable">{{ item.no }}</span>
                        <v-text-field v-else v-model="item.no" outlined dense hide-details />
                    </template>
                    <template #[`item.label`]="{item}">
                        <span v-if="!item.editable">{{ item.label }}</span>
                        <v-text-field v-else v-model="item.label" outlined dense hide-details />
                    </template>
                    <template #[`item.subject`]="{item}">
                        <span v-if="!item.editable">{{ item.subject }}</span>
                        <v-text-field v-else v-model="item.subject" outlined dense hide-details />
                    </template>
                    <template #[`item.image`]="{item}">
                        <v-img v-if="item.image" :src="item.image.src" width="200" max-width="200" min-height="50" contain class="grey lighten-4" />
                        <v-file-btn v-if="item.editable" color="blue" outlined width="100%" accept=".jpg,.png" @change="image => upload(item, image)">
                            <v-icon size="20" left>mdi-cloud-upload</v-icon>이미지 업로드
                        </v-file-btn>
                    </template>
                    <template #[`item.url`]="{item}">
                        <span v-if="!item.editable">{{ item.url }}</span>
                        <v-text-field v-else v-model="item.url" outlined dense hide-details />
                    </template>
                    <template #[`item.actions`]="{item}">
                        <v-btn v-if="!item.editable" color="grey" outlined @click="edit(item)"><v-icon small left>mdi-pencil</v-icon>수정</v-btn>
                        <v-btn v-else color="blue" outlined @click="save(item)"><v-icon small left>mdi-content-save</v-icon>저장</v-btn>
                    </template>
                    <template #[`item.actions2`]="{item}">
                        <v-icon small @click="remove(item)">mdi-delete</v-icon>
                    </template>

                    <template #[`body.append`]>
                        <tr>
                            <td>
                                <v-text-field v-model="editItem.no" outlined dense hide-details />
                            </td>
                            <td>
                                <v-text-field v-model="editItem.label" outlined dense hide-details />
                            </td>
                            <td>
                                <v-text-field v-model="editItem.subject" outlined dense hide-details />
                            </td>
                            <td>
                                <div>
                                    <v-img v-if="editItem.image" :src="editItem.image.src" width="200" max-width="200" min-height="50" contain class="grey lighten-4" />
                                    <v-file-btn color="blue" outlined width="100%" accept=".jpg,.png" @change="image => upload(editItem, image)">
                                        <v-icon size="20" left>mdi-cloud-upload</v-icon>이미지 업로드
                                    </v-file-btn>
                                </div>
                            </td>
                            <td>
                                <v-text-field v-model="editItem.url" outlined dense hide-details />
                            </td>
                            <td>
                                <v-btn color="blue" outlined @click="save(editItem)"><v-icon small left>mdi-content-save</v-icon>저징</v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>

            </v-responsive>
        </v-layout>

    </v-container>
</template>

<script>
import api from "@/api";
import VFileBtn from "@/components/plugins/vuetify/v-file-btn.vue";

export default {
    components: {
        VFileBtn
    },
    created(){
        this.init();
    },
    data(){
        return {
            insights: [],
            insightsHeaders: [
                { text: "no", value: "no", align: "center", width: 100 },
                { text: "라벨(회색)", value: "label", align: "center", width: 200 },
                { text: "제목", value: "subject", align: "left" },
                { text: "이미지", value: "image", align: "center", width: 200 },
                { text: "URL", value: "url", align: "center" },
                { text: "편집", value: "actions", align: "center", width: 100 },
                { text: "-", value: "actions2", align: "center", width: 100 },
            ],

            editItem: {}
        };
    },
    methods: {
        async init(){
            await this.search();
            this.clear();
        },

        async search(){
            var { insights } = await api.console.pansim.insights.gets();
            this.insights = insights;
        },

        clear(){
            this.editItem = {
                no: this.insights.reduce((max, insight) => (max < insight.no ? insight.no : max), 0) + 1,
                label: null,
                subject: null,
                image: null,
                url: null
            };
        },

        edit(insight) {
            this.$set(insight, "editable", true);
        },

        async save(insight) {
            insight._id ? await api.console.pansim.insights.put(insight) : await api.console.pansim.insights.post(insight);
            insight.editable = false;

            alert("저장되었습니다");
            await this.search();
            this.clear();
        },

        async upload(insight, image){
            let { src, name } = await api.console.pansim.insights.image.post(image);
            insight.image = { src, name };
        },

        async remove(insight){
            if(confirm("삭제하시겠습니까?")){
                await api.console.pansim.insights.delete(insight);
                await this.search();
                this.clear();
            }
        },
    },
}
</script>
