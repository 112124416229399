<template>
    <v-container fluid fill-height class="console-terms">
        <v-layout v-if="setting" justify-center>
            <v-responsive max-width="800px" width="100%">
                <v-row class="mt-8" justify="center" align="center">
                    <v-col cols="auto" class="pt-0 pb-0 headline text-start">미디어 설정</v-col>
                </v-row>
                <div class="caption red--text mt-2"><b>* 비디오 아이디 추출 방법</b></div>
                <div class="caption red--text mt-2">유튜브 공유버튼 클릭 -> "https://youtu.be/"와 "?" 사이의 문자 복사하시면 됩니다.</div>
                <div class="caption red--text mt-2">예) "https://youtu.be/hJ431OEvf00?feature=shared" => "hJ431OEvf00" </div>

                <v-card class="mt-4">
                    <v-card-title class="subtitle-2 font-weight-bold">
                        미디어 등록
                    </v-card-title>
                    <v-divider />
                    <media-table/>
                </v-card>

            </v-responsive>
        </v-layout>
    </v-container>
</template>

<script>
import api from "@/api";
import banks from "@/assets/data/banks.json";
import vPasswordField from "@/components/plugins/vuetify/v-password-field.vue";
import CryptoAes from "@/plugins/crypto-aes";
import FooterInfo from "@/components/console/setting/footer-info.vue";
import FooterSns from "@/components/console/setting/footer-sns.vue";
import MediaTable from "@/components/console/media/media-table.vue";

export default {
    components: {
        vPasswordField,
        FooterInfo,
        FooterSns,
        MediaTable
    },
    created() {
        this.init();
    },
    data() {
        return {
            setting: null,
            banks,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            await this.search();
        },

        async search() {
            var { setting } = await api.console.setting.get();
            this.setting = setting;
        },

        meta(name) {
            var meta = this.setting.seo.meta.find((meta) => meta.name == name);
            if (!meta) {
                meta = { name, content: null };
                this.setting.seo.meta.push(meta);
            }
            return meta;
        },

        async save() {
            const setting = JSON.parse(JSON.stringify(this.setting));
            if (setting?.smtp?.password) setting.smtp.password = CryptoAes.encrypt(setting.smtp.password);
            await api.console.setting.put(setting);

            alert("저장되었습니다.");
        },
    },
};
</script>
